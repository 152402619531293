import React from "react";
import AllRoutes from "./router/routes";
import { Provider } from "react-redux";
import { store } from "../src/store/store";
import FCM from "../src/library/FCM";
import SocketClient from "../src/library/SocketClient";
import 'slick-carousel/slick/slick.css'; // Import slick-carousel base styles
import 'slick-carousel/slick/slick-theme.css'; // Import slick-carousel default theme styles

const App = (props) => {
  return (
    <Provider store={store}>
      {/* <Elements stripe={stripePromise} options={options as StripeElementsOptions}> */}
      <AllRoutes />

      <FCM {...props} />
      {/* </Elements> */}
    </Provider>
  );
};

export default App;
