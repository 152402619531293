import React from 'react'
import ChatMessages from '../../components/ChatMessages/ChatMessages'

function ChatMessagesPage() {
  return (
     <ChatMessages />
    
  )
}

export default ChatMessagesPage