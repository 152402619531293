import React, { useState } from "react";
import "./ChatMessages.css";

const ChatMessages = () => {
  const [messages, setMessages] = useState([
    {
      sender: "Maxine HR Manager",
      text: "I will need some more information:\n1. What are the names of the parties\n2. What date did the incident occur?\n3. Who will witness the document?",
      attachments: [],
      avatar: "/assets/images/hr-manager-chat.png", 
    },
    {
      sender: "Maxine HR Manager",
      text: "No worries. See attached the pdf with more information which might help.",
      attachments: [],
      avatar: "/assets/images/hr-manager-chat.png",
    },
    {
      sender: "John Jones (you)",
      text: "",
      attachments: [{ name: "Agreement.pdf", link: "#" }], 
      avatar: "/assets/images/john-chat.png",
    },
    {
      sender: "Maxine HR Manager",
      text: "I have created a checklist for you.",
      attachments: [{ name: "Checklist.pdf", link: "#" }],
      avatar: "/assets/images/hr-manager-chat.png",
    },
  ]);

  return (
    <div className="chat-container">
      <div className="chat-header">
        <div className="title">Call Message</div>
        <div className="close-icon">
          <img src="assets/images/chat-close-icon.png" alt="" />
        </div>
      </div>
       
      <div className="chat-messages">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`chat-bubble ${
              message.sender.includes("you") ? "user-bubble" : "manager-bubble"
            }`}
          >
            <p>{message.text}</p>
            {message.attachments.map((file, i) => (
              <div className="attachment" key={i}>
                <a href={file.link} download className="attachment-link">
                 <img src="/assets/images/document-pdf.png" alt=""/> {file.name}
                </a>
                <a href={file.link} className="download-icon">
                  <img src="/assets/images/download-pdf.png" alt="" />
                </a>
              </div>
            ))}
            
            <div className="chat-info">
              <img src={message.avatar} alt="avatar" className="avatar" />
              <span className="sender-name">{message.sender}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="chat-input">
        <div className="input-box-wrap">
          <input type="text" placeholder="Type message..." />
          <div className="attach-file">
            <img src="/assets/images/attatchment-icon.png" alt="" />
          </div>
        </div>
        
        <button type="submit" className="send-btn"><img src="/assets/images/send-icon.png" alt="" /></button>
      </div>
    </div>
  );
};

export default ChatMessages;
