import { getCurrentYear } from "../UtilityFunctions";
import parse from "html-react-parser";

export const STRING_CONSTANTS = {
  // ## Form labels.
  SOMETHING_WENT_WRONG: "Something went wrong",
  userType: {
    employee: "Mploi-ie",
    employer: "Mploi-er",
  },
  common: {
    location: "Location",
    access_denied: "Access Denied",
    permission_denied: "Enable location permission for browser.",
  },
  formLabelString: {
    label_welcome: "Welcome Back!",
    label_logo: "LOGO",
    label_first_name: "First Name",
    label_last_name: "Last Name",
    label_email: "Email Address",
    label_number: "Phone Number",
    label_dateofbirth: "Date of Birth",
    label_gender: "Gender",
    label_male: "Male",
    label_female: "Female",
    label_other: "Other",
    label_password: "Password",
    label_confirm_password: "Confirm Password",
    label_login: "Login",
    label_logout: "logout",
    label_signup: "Sign up",
    resiter_your_self: "Register Your Self",
    label_select_option: "Select Item",
    label_toast_email: "Email",
    label_otp: "Otp",

    // Placeholders
    label_email_plc: "Enter Email",
    label_password_plc: "Enter Password",
    label_first_name_plc: "Enter your First Name.",
    label_last_name_plc: "Enter your Last Name.",
    label_number_plc: "Enter your Number.",
    label_mobile_plc: "000 0000 000",
    label_confirm_password_plc: "Confirm Password",
    label_abn_plc: "Enter Business ABN (optional)",
    label_contact_name: "Enter Contact Name",
    label_primary_address: "Enter Primary Business Address",
    label_new_password: "New Password",
    label_old_password: "Old Password",
    label_confirm_new_password: "Confirm Password",
    label_bussiness_name: "Business Name",
    label_trading_name: "Trading Name",
    label__contact_name: "Contact Name",
    label_contact_number: "Contact Number",
    label_mob_number: "Mobile number",
    label_abn_number: "ABN",
    label_invalid_abn_number: "Invalid ABN",
    label_profile_updated: "Update Profile",
    label_delete_account: "Delete your account?",
    label_del_toast: "Delete Account",
    label_account: "Account",
    label_logout_popup: "Logout of your account?",
    label_salary_range: "Salary Range",
    label_salary_type: "Salary Checkout Type",
    label_job_type: "Job Type",
    label_flag: "Flagged",
    label_flag_error: "Error occured!",
    label_reason: "Reason!",
  },
  // Onboarding Screen Strings
  onboardingScreen: {
    screen1: {
      title: parse(
        "Find your <span>dream</span> career <br /> or next employee!"
      ),
      subtitle: parse(
        "MPLOI instantly matches likeminded<br/> employees to employers based on similar<br/> values, qualities and skills. It’s FREE to sign up!<br/> Claim your Profile now!"
      ),
    },
    screen2: {
      title: parse(
        "<span>Instant</span> message <br/> between potential <br/> employees & employers"
      ),
      subtitle: parse(
        "MPLOI instantly matches likeminded<br/> employees to employers based on similar<br/> values, qualities and skills. It’s FREE to sign up!<br/> Claim your Profile now!"
      ),
    },
    screen3: {
      title: parse("Find the <span>perfect</span><br/> opportunities for you"),
      subtitle: parse(
        "MPLOI instantly matches likeminded<br/> employees to employers based on similar<br/> values, qualities and skills. It’s FREE to sign up!<br/> Claim your Profile now!"
      ),
    },
    buttons: {
      login: "Login",
      signup: "Sign Up",
      account_exists: "Already have an account?",
      next_screen: "Next",
      skip_all: "Skip",
    },
  },
  signupPages: {
    common: {
      email_receipt: " Didn’t recieve an email?",
      otp_receipt: "Didn't receive a code?",
      open_mail: parse("Open your email to verify <br/> your account with us!"),
      mobile_otp: parse(
        "We will send you a one time <br/> code on this mobile number."
      ),
      verified: parse("Congrats, your account <br /> has been verified!"),
    },
    buttons: {
      submit: "Submit",
      account_verified: "Account Verified",
      customise_profile: "Customise Profile",
    },
    signup: {
      sign_up: "Sign Up",
      account_exists: "Already have an account?",
      first_step: parse(
        "Your first steps in finding <br/> your next champion!"
      ),
      step: "Step 2/6",
      agree_terms: "By proceeding to sign up you agree to our",
      terms: "terms",
      services: "services",
    },
    verifyEmail: {
      verify_email: "Verify Email",
      open_mail: parse("Open your email to verify <br/> your account with us!"),
      step: "Step 3/6",
      email_receipt: " Didn’t recieve an email?",
      resend_prompt: "Resend email in",
      resend_email: "Resend",
    },
    verifyMobile: {
      verify_mobile: "Verify Mobile",
      mobile_otp: parse(
        "We will send you a one time <br/> code on this mobile number."
      ),
      step: "Step 4/6",
    },
    step4: {
      verify_mobile: "Verify Mobile",
      mobile_otp_sent: parse("We have sent you a one time <br/> code on "),
      step: "Step 5/6",
      otp_receipt: "Didn't receive a code?",
      otp_prompt: "Resend OTP in",
      resend_otp: "Resend",
    },
    //skipping below step 6 & 7
    step5: {
      step: "Step 5/7",
      choose_plan: parse("Choose the plan that's <br/> right for you"),
      continue_button: "Continue",
    },
    step6: {
      payment: "Payment",
      add_payment_method: "Add a payment method",
      step: "Step 6/7",
      cardholder_name: "Enter Card Holder Name",
    },
    accountVerified: {
      step: "Step 6/6",
      reg_success: "Registration Successful",
      cong_success: "Congrats, registration completed successfully",
    },
  },
  employerProfileCompletionPages: {
    abn_validation: {
      step: "Step 1/5",
      trading_details: "Trading Details",
      enter_abn: parse("Enter your business ABN <br/> to validate"),
    },
    business_details: {
      step: "Step 2/5",
      business_details: "Business Details",
      enter_business_details: parse(
        "Enter your business and <br/> upload a logo"
      ),
    },
    business_location: {
      step: "Step 3/5",
      business_location: "Business Location",
      enter_business_location: parse("Enter the <br/> Business location"),
      add_secondary: "Add Secondary",
      current_location: "Use Current Location",
    },
    business_contact: {
      step: "Step 4/5",
      business_contact: "Business Contact",
      enter_contact: parse("Add a contact <br/> for the business"),
    },
    profile_completed: {
      step: "Step 5/5",
      profile_created: "Profile Created",
      start_hiring: "Start Hiring",
      view_home: "View Home",
      completed: parse("Congrats, your account <br/> has been created!"),
      under_process: parse(
        "Congrats, your verification is <br/> being processed!"
      ),
    },
  },
  // ## Validation message strings.
  validationString: {
    req_email: "Please enter your email.",
    req_email_incorrect: "Please enter your correct email.",
    req_password: "Please enter your password.",
    req_password_notvalid: "Please enter a valid password.",
    req_first_name: "Please enter your First Name.",
    req_last_name: "Please enter your Last Name.",
    req_number: "Please enter your number",
    req_number_incorrect: "Please enter your Correct Number.",
    req_dateofbirth: "Please enter your Date of Birth.",
    req_gender: "Please choose your Gender",
    req_confirm_password: "Please enter your Confirm Password.",
    req_mobileNumber: "Please enter your number.",
    req_otp: "Please enter valid otp",
    min_chars: "Min. 8 characters",
    number_req: "1 Char. should be a number",
    white_space_in_password: "Please remove space from password",
    req_old_password: "Old password is required",
    req_new_password: "New password is required",
    req_same_password: "New password is not same as confirm password",
    req_trading_name: "Trading name is required",
    req_business_name: "Business name is required",
    req_contact_name: "Contact name is required",
    req_contact_number: "Contact number is required",
    req_mob_number: "Mobile number is required",
    req_abn: "Please enter a valid ABN",
    req_valid_abn: "Ensure this field has 11 characters",
    req_profile_updated: "Profile updated successfully",
    req_delete_account: "Successfully deleted account",
    req_del_field: "Please enter 'delete' in text field",
    req_job_type: "Please select a job type before proceeding",
    req_checkout_type: "Please select a salary checkout type before proceeding",
    req_salary_empty: "Please enter a valid number",
    req_salary_valid:
      "Minimum salary cannot be greater than or equal to maximum salary",
    req_flag: "User has been successfully flagged",
    req_retry: "Please try again after some time",
    req_reason:
      "Please select atleast one reason from the given list of reasons.",
    req_changes: "Please check fields there are no changes",
  },
  // ## Routes String
  navigationString: {
    index: "/",
    test: "/test",
    login: "/signin",
    signup: "/signup",
    forgotpassword: "/forgotpassword",
    resetpassword: "/resetpassword",
    terms: "/terms",
    privacy: "/privacy",
    onboardingTutorial: "/tutorial",
    logout: "/logout",
    guardianDocumentUpload: "/:uidb/:token/guardian-document-upload/",
    chatRequest: "/chat-request",
    testNew: "/testnew",
    about: "/about",
    competition: "/competition",
    contact: "/contact",
    pricing: "/pricing",
    blog_details: "/blog-details",
    dashboard: {
      home: "/dashboard/home",
      settings: "/dashboard/settings",
      chat: "/dashboard/chat",
      create_job: "/dashboard/create-job/:type/:step?",
      job_creation: "/dashboard/job-creation",
      privacy: "/privacy",
      terms: "/terms",
      password: "/password",
      notifications: "/dashboard/notifications",
      verified: "/dashboard/verified",
      failed: "/dashboard/failed"

    },
    select_plan: "/subscriptions",
    add_card_details: "/add-card-details",
    employer_profile_completion: "/employer/profilecompletion",
    employee_profile_completion: "/employee/profilecompletion",
    advanced_profile: "/advancedprofile",
    subscription_web: "/subscription-web",
    for_employees: '/employees',
    for_employers: '/employers',
    blogs: '/blogs',
  },
  // ## Cookie Names
  cookieStrings: {
    user: "user",
    token: "token",
    userData: "userData",
    planSelected: "planSelected",
    apiError: "apiError",
    jobCategories: "category",
    isGuest: "isGuest",
  },
  footerStrings: `Copyright © ${getCurrentYear()} Appboxer .All rights reserved.`,
  page404: {
    page_not_found: "Sorry, The page you're looking for not found.",
    back_to_home: "Back To Home",
  },

  API_REQUEST: {
    GET: "GET",
    PATCH: "PATCH",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
  },
  API_HEADERS: {
    CONTENT_TYPE: "Content-Type",
    AUTHORIZATION: "Authorization",
    TYPE_FORM_DATA: "multipart/form-data",
    TYPE_RAW_DATA: "application/json",
    TYPE_MULTIPART_DATA: "multipart/form-data",
    TOKEN_PREFIX: "Bearer",
  },
  API_ERRORS: {
    INTERNAL_SERVER_ERROR_HEADER: "Server error",
    INTERNAL_SERVER_ERROR: "500 Internal server error",
    DEACTIVE_USER: "Access error",
  },
  API_PARAMS: {
    WEB: "web",
  },
  API_401: {
    HEADER: "Message",
    MESSAGE: "User not Registered with the MPLOI with provided email ID"
  },
  SUCCESS_MESSAGES: {
    SUCCESS_HEADER: "Success",
    EMAIL_SENT_MESSAGE:
      "A link for resetting the password has been sent to your registered Email ID.",
    OTP_SENT_MESSAGE: "Resend OTP successfully.",
    RESEND_VERIFICATION_EMAIL: "Email sent successfully.",
    EMAIL_VERIFIED: "Your email has already been verified !",
    PASSWORD_UPDATED: "Your password has been updated!",
    CHAT: "Chat",
    CHAT_REQUEST_SENT: "Chat Request Sent",
  },
  GUARDIAN: {
    NO_DOCUMENT: "No document selected! Please select atleast one.",
    DOCUMENTS: "Documents",
    TWO_DOCUMENTS_UPLOADED: "Already two documents have been uploaded!",
    documents_submitted: parse(
      "Congrats, your verification is <br /> being processed"
    ),
    DOCUMENT_UPLOAD: "Please upload up to two ID documents to verify",
    THANK_YOU_MESSAGE:
      "Thank you for completing the verification process we will review your ID.",
    LARGE_IMAGE_FILE_SIZE_ERROR: "Image is more than 2MB.",
    buttons: {
      guardian_verification: "Guardian Verification",
    },
  },

  STRIPE_SUBSCRIPTION: {
    0: "Monthly",
    1: "Yearly",
    UPDATED: "Subscription has been updated!",
    ADD_PAYMENT_METHOD: "Add a payment method",
    CARD: "CARD",
    SUBSCRIPTION: "Subscription",
    SELECT_PLAN: "Select atleast one plan.",
  },

  //constant for create jobs
  create_job: {
    create_a_job: "Create a Job",
    step: "Step",
    final_step: "8",
    cat_heading: "Select a job category that applies to your new job",
    cat_search_placeholder: "Search Industry",
    sub_cat_search_placeholder: "Search Job Title",
    sub_cat_heading: "Select a job subcategory that applies to your new job",
    image_heading: "Upload a cover image for your job post",
    image_placeholder: "Upload Image",
    image_btn: "Job Image",
    job_desc_btn: "Job Description",
    job_desc_heading: "Enter your job description",
    job_desc_date_placeholder: "Start Date (optional)",
    job_desc_placeholder: "Enter Job Decription",
    location_heading: "Where will your job be located?",
    location_placeholder: "Enter Business Address",
    location_crt_placeholder: "Use current location",
    job_exp_heading: "How many years of experience is required?",
    job_salary_btn: "Job Salary",
    job_salary_heading: "What rate is your job offering?",
    job_comp_btn: "Job Complete",
    job_comp_heading: "Congrats, your job is now live for the next 28 days",
    start_hiring: "Start Hiring",
    cat: "Category",
    sub_cat: "Sub Category",
    location: "Location",

    //errors
    cat_req: "Please select category.",
    sub_cat_req: "Please select sub category.",
    image_req: "Please select image.",
    img: "Image",
    req_date: "Please enter date.",
    req_desc: "Please enter description.",
    req_location: "Please select location.",
  },
  modal_text: {
    delete_text:
      "If you delete your account you will lose all data associated with MPLOI. Please type “delete” to confirm the termination of your account.",
    logout_text: "Please confirm that you wish to logout of your account.",
  },
  employeeProfileCompletionPages: {
    step1: {
      step: "Step 1/18",
      title: "Create Profile",
      subtitle: "Tell us your name and add your profile photo",
      upload_image: parse("Upload <br /> Image"),
      enter_full_name: "Enter Full Name",
      enter_dob: "Enter DOB",
      enter_about_me: "Enter About Me",
    },
    step2: {
      step: "Step 2/18",
      title: "Video",
      subtitle: "Upload a 30s Self Introductory Video",
      upload_video: "Upload Video",
    },
    step3: {
      step: "Step 3/18",
      title: " Create Profile",
      subtitle: "What type of job are you looking for?",
      search_industry: "Search Industry",
    },
    step4: {
      step: "Step 4/18",
      title: "Create Profile",
      subtitle: "Select a job subcategory that applies to your new job",
      search_job_subcategory: "Search Job Subcategory",
    },
    step5: {
      step: "Step 5/18",
      title: "Create Profile",
      subtitle: "Select your location and how far you would like to travel",
      enter_address: "Enter Address",
      use_current_location: "Use current location",
      kms: "KM's",
    },
    step6: {
      step: "Step 6/18",
      title: " Create Profile",
      subtitle: "How many years of experience do you have?",
    },
    step7: {
      step: "Step 7/18",
      title: " Create Profile",
      subtitle: parse("What is your expected<br /> salary?"),
      add_range: "+ Add Range",
      remove_range: "- Remove Range",
      start_value: "Enter Start Value",
      enter_end_value: "Enter End Value",
    },
    step8: {
      step: "Step 8/18",
      title: "Create Profile",
      subtitle: "Do you have a current employer?",
      enter_business_name: "Enter Business Name",
      search_employer_ph: "Search employer...",
      no_results_found: "No results have been found matching ",
      search_again: "Search Again",
      employer_since: "Employed Since",
      employer_till: "Employed Till (optional)",
      hide_profile: "Hide your profile from employer?",
      cant_find_employer: "Can’t find employer?",
      enter_name: "Enter Name",
      search_employer: "Search employer?",
    },
    step9: {
      step: "Step 9/18",
      title: "Create Profile",
      subtitle: "Do you want to hide your profile from additional employers",
      search_employer_ph: "Search employer...",
      no_results_found: "No results have been found matching ",
      search_again: "Search Again",
      max_employers: "2 Employers Max",
      skip: "Skip",
    },
    step10: {
      step: "Step 10/18",
      view_home: "View Home",
      disclaimer:
        "We respect your privacy so will only be displaying your first name and suburb to other users",
      ok_thanks: "Ok, thanks",
    },
  },
  advanced_profile: {
    // step1: {
    //   step: "Step 1/10",
    //   title: "Advanced Profile",
    //   subtitle: "Upload your photo ID",
    //   upload_image: "Can’t find employer?",
    //   enter_name: "Enter Name",
    //   experience: "Experience",
    // },
    step1: {
      step: "Step 10/18",
      title: "Advanced Profile",
      subtitle: parse("Who were your previous <br/> employers?"),
      search_employer_ph: "Search employer...",
      search_employer_question: "Search employer?",
      enter_business_name: "Enter Business Name",
      cant_find_employer: "Can’t find employer?",
      enter_name: "Enter Name",
      experiences: "Experiences",
      no_results: "No results have been found matching ",
      search_again: "Search Again",
    },
    step2: {
      step: "Step 11/18",
      title: "Advanced Profile",
      subtitle: parse("What type of industry <br/> was your past job?"),
      search_industry: "Search Industry",
    },
    step3: {
      step: "Step 12/18",
      title: "Advanced Profile",
      subtitle: parse(
        "Select a job subcategory <br/> that applies to past job"
      ),
      search_job_title: "Search Job Title",
    },
    step4: {
      step: "Step 13/18",
      title: "Advanced Profile",
      subtitle: parse("How long were you in <br/> the position?"),
      start_date: "Employed Start Date",
      end_date: "Employed End Date",
      hide_profile: "Hide profile from employer?",
    },
    step5: {
      step: "Step 14/18",
      title: "Advanced Profile",
      subtitle: parse("Employer has been added to <br/> my experiences list"),
      add_another_employer: "+ Add Another Employer",
    },
    step6: {
      step: "Step 15/18",
      title: "Advanced Profile",
      subtitle: parse("What are your qualifications?"),
      institution_type: "Select type of Institution",
      institution_name: "Enter Institution Name",
      course_type: "Select type of Course",
    },
    step7: {
      step: "Step 16/18",
      title: "Advanced Profile",
      subtitle: parse("How long was <br/> your course?"),
      start_date: "Start Date",
      end_date: "End Date (Optional)",
    },
    step8: {
      step: "Step 17/18",
      title: "Advanced Profile",
      subtitle: parse(
        "Institution has been added <br/> to my qualifications list"
      ),
      add_another: "+ Add Another Institution",
    },
    step9: {
      step: "Step 18/18",
      title: "Advanced Profile",
      subtitle: parse(
        "Congrats, you have completed <br/> your advanced profile"
      ),
      start_exploring: "Start Exploring",
    },
  },
};
