import React, { useEffect, useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { STRING_CONSTANTS } from "../../utils/constants/stringConstants";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 850);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const currentPath = window.location.pathname;

  return (
    <header className="header">
      <div className="logo">
        <img src="/assets/images/mploi-logo-white.svg" alt="header logo" />
      </div>
      <div className="menu-icon" onClick={toggleMenu}>
        <img src="/assets/images/header-menu-icon.svg" alt="menu icon" />
      </div>
      {isMobile ? (
        <nav className={`nav ${isMenuOpen ? "open" : ""}`}>
          <ul className="nav-links">
            <li>
              <Link to={STRING_CONSTANTS.navigationString.about}>About</Link>
            </li>
            <li>
              <Link to={STRING_CONSTANTS.navigationString.for_employers}>
                For Employers
              </Link>
            </li>
            <li>
              <Link to={STRING_CONSTANTS.navigationString.for_employees}>
                For Employees
              </Link>
            </li>
            <li>
              <Link to={STRING_CONSTANTS.navigationString.competition}>
                Competition
              </Link>
            </li>
            <li>
              <Link to={STRING_CONSTANTS.navigationString.pricing}>
                Pricing
              </Link>
            </li>
            <li>
              <Link to={STRING_CONSTANTS.navigationString.blogs}>Blog</Link>
            </li>
            <li>
              <a href="https://mploiacademy.com/">Academy</a>
            </li>
          </ul>
          <div className="auth-buttons">
            <button className="login">Log In</button>
            <button className="signup">Sign Up</button>
          </div>
        </nav>
      ) : (
        <>
          <ul className="nav-links">
            <li
              className={
                currentPath === STRING_CONSTANTS.navigationString.about
                  ? "active"
                  : ""
              }
            >
              <Link to={STRING_CONSTANTS.navigationString.about}>About</Link>
            </li>
            <li
              className={
                currentPath === STRING_CONSTANTS.navigationString.for_employers
                  ? "active"
                  : ""
              }
            >
              <Link to={STRING_CONSTANTS.navigationString.for_employers}>
                For Employers
              </Link>
            </li>
            <li
              className={
                currentPath === STRING_CONSTANTS.navigationString.for_employees
                  ? "active"
                  : ""
              }
            >
              <Link to={STRING_CONSTANTS.navigationString.for_employees}>
                For Employees
              </Link>
            </li>
            <li
              className={
                currentPath === STRING_CONSTANTS.navigationString.competition
                  ? "active"
                  : ""
              }
            >
              <Link to={STRING_CONSTANTS.navigationString.competition}>
                Competition
              </Link>
            </li>
            <li
              className={
                currentPath === STRING_CONSTANTS.navigationString.pricing
                  ? "active"
                  : ""
              }
            >
              <Link to={STRING_CONSTANTS.navigationString.pricing}>
                Pricing
              </Link>
            </li>
            <li
              className={
                currentPath === STRING_CONSTANTS.navigationString.blogs
                  ? "active"
                  : ""
              }
            >
              <Link to={STRING_CONSTANTS.navigationString.blogs}>Blog</Link>
            </li>
            <li>
              <Link to="">Academy</Link>
            </li>
          </ul>
          <div className="auth-buttons">
            <Link to="/signIn">
              <button className="login">Log In</button>
            </Link>
            <Link to="/signUp">
              <button className="signup">Sign Up</button>
            </Link>
          </div>
        </>
      )}
    </header>
  );
}

export default Header;
